.react-datepicker {
  font-family: 'Arial', sans-serif;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.react-datepicker__header {
  background-color: #9151D2;
  border-bottom: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding-top: 10px;
}

.react-datepicker__current-month {
  color: white;
  font-weight: bold;
}

.react-datepicker__day-name {
  color: white;
}

.react-datepicker__day {
  color: #333;
  border-radius: 50%;
}

.react-datepicker__day:hover {
  background-color: #f0f0f0;
}

.react-datepicker__day--selected {
  background-color: #9151D2;
  color: white;
}

.react-datepicker__day--keyboard-selected {
  background-color: #b384e6;
  color: white;
}

.react-datepicker__navigation {
  top: 15px;
}

.react-datepicker__navigation-icon::before {
  border-color: white;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover {
  background-color: #f0f0f0;
}

.react-datepicker__year-option--selected_year,
.react-datepicker__month-option--selected_month {
  background-color: #9151D2;
  color: white;
}
